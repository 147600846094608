import * as tslib_1 from "tslib";
import api from "@/api";
import { Component, Mixins, } from "vue-property-decorator";
import Base from "@/templates/add";
import MyDialog from "./dialog";
let Intelligent = class Intelligent extends Mixins(Base) {
    constructor() {
        super(...arguments);
        // data
        this.emissionReductionProjectList = [];
        this.factorActivityLevelTypeEnums = [];
        this.factorFrequencyTypeEnums = [];
        this.factorQualityLevelEnums = [];
        this.emissionReductionFactorList = [];
        this.recommendedProgramList = [{}];
        this.deptTree = [];
        this.userEnums = [];
        this.tempRow = {};
        this.tempIndex = "";
        this.deviceInfoForm = {
            year: "2022",
        };
        this.factorType = "1";
        this.buttonloading = false;
        this.loading = true;
        this.rules = {};
    }
    // computed;
    get isModeAdd() {
        return !this.$route.params.id;
    }
    get isModeEdit() {
        return !!this.$route.params.id;
    }
    get isModeInfo() {
        return !!this.$route.params.id;
    }
    created() {
        // var r: Color = Color.Red
        // var g: Color = Color.Green
        // var b: Color = Color.Blue
        // console.log('r', Color.Red + ',' + Color.Red2)
        this.getInfo();
    }
    mounted() { }
    // methods
    init() { }
    handleChangeDept(orgs) {
        var orgId = orgs;
        this.deviceInfoForm.principalId = "";
        api.user.queryUserList({ orgId: orgId }).then((res) => {
            this.userEnums = res.data.map((ele) => ({
                label: ele.userName,
                value: ele.id,
            }));
        });
    }
    handleSumRow(params) {
        console.log("params", params);
        const { columns, data } = params;
        console.log("columns", columns);
        const sums = [];
        var totalEmissionReductionCount = 0;
        var consumeMaterialUnit = this.$t("Intelligent.吨"); // data[0] ? data[0].consumeMaterialUnit : '/'
        data.forEach((ele) => {
            totalEmissionReductionCount += ele.emissionReductionCount;
        });
        columns.forEach((column, index) => {
            switch (column.property) {
                case "emissionReductionCount":
                    sums[index] =
                        totalEmissionReductionCount + " " + consumeMaterialUnit;
                    break;
                default:
                    sums[index] = "";
            }
        });
        sums[0] = "合计";
        return sums;
    }
    handleEditRow(row) {
        this.$refs.myDialog.edit(row);
    }
    handleMail(row) {
        // var a = document.createElement('a')
        // a.href = 'mailto:info@carbonebook.com?subject=这是邮件的主题&body=' + row.description
        // a.click()
        this.$confirm(this.$t("Intelligent.确定向平台专家咨询该方案吗"), {
            type: "warning",
        }).then(() => {
            api.carbonSmartReduction
                .sendMail({
                id: row.id,
            })
                .then((res) => {
                this.$message.success(res.msg);
                row.status = 1;
            });
        });
    }
    getInfo() {
        this.loading = true;
        api.carbonSmartReduction
            .queryEmissionReductionList({
            customId: this.$store.state.user.customId,
            year: this.deviceInfoForm.year,
        })
            .then((res) => {
            console.log("res", res);
            this.loading = false;
            this.emissionReductionProjectList =
                res.data.emissionReductionProjectList;
            this.emissionReductionFactorList = res.data.emissionReductionFactorList;
            this.recommendedProgramList = res.data.recommendedProgramList;
        });
    }
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
            if (valid) {
                this.buttonloading = true;
                // var timeString = this.$moment(this.deviceInfoForm.visitTime).format('YYYY-MM-DD HH:mm:ss')
                // var picUrl = this.imageUrl
                var query = Object.assign({}, this.deviceInfoForm);
                console.log("query", query);
                // if (2 > 1) {
                //   return
                // }
                if (this.isModeAdd) {
                    api.emission
                        .save(query)
                        .then(() => {
                        this.buttonloading = false;
                        this.$router.go(-1);
                    })
                        .catch((_) => {
                        this.buttonloading = false;
                    });
                }
                // 编辑
                if (this.isModeEdit) {
                    api.emission
                        .update(Object.assign({ id: this.$route.params.id }, query))
                        .then(() => {
                        this.buttonloading = false;
                        this.$router.go(-1);
                    })
                        .catch((_) => {
                        this.buttonloading = false;
                    });
                }
            }
            else {
                return false;
            }
        });
    }
};
Intelligent = tslib_1.__decorate([
    Component({
        components: {
            MyDialog,
        },
    })
], Intelligent);
export default Intelligent;
