<template>
  <div v-if="dialogFormVisible2">
    <el-dialog
            :title="titleTable"
            :visible.sync="dialogFormVisible"
            :width="'480px'"
            @close="close"
            :close-on-click-modal="false"
    >
      <el-form
              :model="deviceInfoForm"
              :rules="rules"
              ref="form"
              :label-width="'120px'"
              label-position="right"
              :class="{ 'my-form-readonly': isModelInfo }"
              size="large"
      >
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item prop="provinceId" :label="$t('add.所在地')">
                <el-cascader
                        style="width: 100%;"
                        v-model="deviceInfoForm.provinceId"
                        :options="options"
                        :placeholder="$t('global.请选择')"
                        :props="{ checkStrictly: false,emitPath:false,value:'id',children:'node',label:'name' }"
                       ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item
                    prop="totalArea"
                    :label="$t('Intelligent.屋顶总面积')"
            >
              <el-input
                      v-model="deviceInfoForm.totalArea"
                      :placeholder="$t('global.请输入')"
                      size="small"
              ><span slot="append">m²</span></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item
                    prop="power"
                    :label="$t('Intelligent.单块面板功率')"
            >
              <el-input
                      v-model="deviceInfoForm.power"
                      :placeholder="$t('global.请输入')"
                      size="small"
              ><span slot="append">W</span></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item
                    prop="singleBlockArea"
                    :label="$t('Intelligent.单块面板面积')"
>
              <el-input
                      v-model="deviceInfoForm.singleBlockArea"
                      :placeholder="$t('global.请输入')"
                      size="small"
              ><span slot="append">m²</span></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button type="primary" :loading="posting" size="small" @click="close()">
            {{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close" size="small">{{ $t("global.cancel") }}</el-button>
          <el-button
                  type="primary"
                  :loading="posting"
                  size="small"
                  @click="submitForm('form')"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import base from '@/templates/add'
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    return {
      titleTable: this.$t('Intelligent.光伏计算器'),
      dialogFormVisible2: false,
      dialogFormVisible: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      isModelInfo: false,
      tempId: '',
      deviceInfoForm: {
      },
      options: [],
      rules: {
        provinceId: [
          { required: true, message: this.$t('global.请选择'), trigger: ['blur', 'change'] }
        ],
        totalArea: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        power: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        singleBlockArea: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ]
      }
    }
  },
  created () {
    api.carbonSmartReduction.queryProvinceList({}).then(res => {
      this.options = res.data
    })
  },
  methods: {
    show () {
      this.dialogFormVisible2 = true
      this.$nextTick((_) => {
        this.dialogFormVisible = true
      })
    },
    close () {
      this.isModelEdit = false
      this.isModelAdd = false
      this.isModelInfo = false
      this.dialogFormVisible = false
      this.resetForm()
      this.$nextTick((_) => {
        this.dialogFormVisible2 = false
      })
    },
    add () {
      this.isModelAdd = true
      this.show()
    },
    edit (data) {
      console.log('data', data)
      this.isModelEdit = true
      this.deviceInfoForm = { ...data.recommendedProject }
      this.tempId = data.id
      this.show()
    },
    view (data) {
      this.isModelInfo = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    // 重制
    resetForm () {
      this.deviceInfoForm = {}
      this.$refs.form.resetFields()
    },
    // 提交
    submitForm (formName) {
      console.log(this.deviceInfoForm)
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.posting = true

          const query = {
            customId: this.$store.state.user.customId,
            emissionReductionProjectList: this.$parent.emissionReductionProjectList,
            year: this.$parent.deviceInfoForm.year
          }

          query.emissionReductionProjectList[0].recommendedProjectDTO = {
            ...this.deviceInfoForm,
            id: this.tempId
          }
          try {
            await api.carbonSmartReduction.saveRecommendedProject(query)
            this.posting = false
            this.$message.success(this.$t('global.submitOk'))
            this.close()
            this.$parent.getInfo()
          } catch (error) {
            this.posting = false
          }
        }
      })
    }
  }
}
</script>
